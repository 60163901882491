import { configureStore } from "@reduxjs/toolkit";
import GuideReducer from "./features/GuideSlice";
import NavBarRuducer from "./features/NavBarSlice";
import LanguageReducer from "./features/LanguageSlice";
import sharedLayoutReducer from "./features/SharedLayoutSlice";
import responsiveReducer from "./features/ResponsiveSlice";
import utilitiesReducer from './features/UtilitiesSlice'

const store = configureStore({
  reducer: {
    guide: GuideReducer,
    nav: NavBarRuducer,
    language: LanguageReducer,
    sharedLayout: sharedLayoutReducer,
    responsive: responsiveReducer,
    utilities: utilitiesReducer,
  },
});

export default store;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  utilsTheCampusActiveNumber: 1,
  utilsThecampusActiveSubmenuNumber: 1,
  // utilsTheCanpusActiveNumberList: [
  //   {
  //     id: 1,
  //     text: "Công Viên Khoa Học",
  //     scene: "../file360/thecampus_tien_ich/index.html?startscene=scene_ti_3",
  //     submenu: [
  //       {
  //         id: 1,
  //         text: "Thám Hiểm Trong Rừng",
  //         scene: "../file360/thecampus_tien_ich/index.html?startscene=scene_ti_3",
  //       },
  //       {
  //         id: 2,
  //         text: "Lớp Học Trong Rừng",
  //         scene: "../file360/thecampus_tien_ich/index.html?startscene=scene_ti_2",
  //       },
  //       {
  //         id: 3,
  //         text: "Cắm Trại Trong Rừng",
  //         scene: "../file360/thecampus_tien_ich/index.html?startscene=scene_ti_5",
  //       },
  //     ],
  //   },
  //   {
  //     id: 2,
  //     text: "Công Viên Nhiệt Đới",
  //     scene: "../file360/thecampus_tien_ich/index.html?startscene=scene_ti_1",
  //   },
  //   {
  //     id: 3,
  //     text: "Công Viên Sáng Tạo",
  //     scene: "../file360/thecampus_tien_ich/index.html?startscene=scene_ti_11",
  //   },
  //   {
  //     id: 4,
  //     text: "Kid Playground",
  //     scene: "../file360/thecampus_tien_ich/index.html?startscene=scene_ti_6",
  //   },
  //   {
  //     id: 5,
  //     text: "Công Viên Thể Thao",
  //     scene: "../file360/thecampus_tien_ich/index.html?startscene=scene_ti_7",
  //     submenu: [
  //       {
  //         id: 4,
  //         text: "Trò Chơi Liên Hoàn",
  //         scene: "../file360/thecampus_tien_ich/index.html?startscene=scene_ti_7",
  //       },
  //       {
  //         id: 5,
  //         text: "Khu Leo Núi",
  //         scene: "../file360/thecampus_tien_ich/index.html?startscene=scene_ti_8",
  //       },
  //       {
  //         id: 6,
  //         text: "Sân Thể Thao",
  //         scene: "../file360/thecampus_tien_ich/index.html?startscene=scene_kt_2",
  //       },
  //     ],
  //   },
  //   {
  //     id: 6,
  //     text: "Khu Vườn Của Bé",
  //     scene: "../file360/thecampus_tien_ich/index.html?startscene=scene_ti_10",
  //   },
  //   {
  //     id: 7,
  //     text: "Nông Trại Vui Vẻ",
  //     scene: "../file360/thecampus_tien_ich/index.html?startscene=scene_ti_9",
  //   },
  //   {
  //     id: 8,
  //     text: "Không Gian Học Tập",
  //     scene: "../file360/thecampus_tien_ich/index.html?startscene=scene_kt_4",
  //   },
  //   {
  //     id: 9,
  //     text: "Hẻm Dân Gian",
  //     scene: "../file360/thecampus_tien_ich/index.html?startscene=scene_ti_12",
  //   },
  //   {
  //     id: 10,
  //     text: "Trường Học",
  //     scene: "../file360/thecampus_tien_ich/index.html?startscene=scene_ti_13",
  //   },
    
  // ],
  utilsTheCanpusActiveNumberList: [
    {
      id: 1,
      text: "Công Viên Khoa Học",
      scene: "scene_ti_3",
      submenu: [
        {
          id: 1,
          text: "Thám Hiểm Trong Rừng",
          scene: "scene_ti_3",
        },
        {
          id: 2,
          text: "Lớp Học Trong Rừng",
          scene: "scene_ti_2",
        },
        {
          id: 3,
          text: "Cắm Trại Trong Rừng",
          scene: "scene_ti_5",
        },
      ],
    },
    {
      id: 2,
      text: "Công Viên Nhiệt Đới",
      scene: "scene_ti_1",
    },
    {
      id: 3,
      text: "Công Viên Sáng Tạo",
      scene: "scene_ti_11",
    },
    {
      id: 4,
      text: "Kid Playground",
      scene: "scene_ti_6",
    },
    {
      id: 5,
      text: "Công Viên Thể Thao",
      scene: "scene_ti_7",
      submenu: [
        {
          id: 4,
          text: "Trò Chơi Liên Hoàn",
          scene: "scene_ti_7",
        },
        {
          id: 5,
          text: "Khu Leo Núi",
          scene: "scene_ti_8",
        },
        {
          id: 6,
          text: "Sân Thể Thao",
          scene: "scene_kt_2",
        },
      ],
    },
    {
      id: 6,
      text: "Khu Vườn Của Bé",
      scene: "scene_ti_10",
    },
    {
      id: 7,
      text: "Nông Trại Vui Vẻ",
      scene: "scene_ti_9",
    },
    {
      id: 8,
      text: "Không Gian Học Tập",
      scene: "scene_kt_4",
    },
    {
      id: 9,
      text: "Hẻm Dân Gian",
      scene: "scene_ti_12",
    },
    {
      id: 10,
      text: "Trường Học",
      scene: "scene_ti_13",
    },
    
  ],
};

const utilitiesSlice = createSlice({
  name: "utilities",
  initialState,
  reducers: {
    setUtilsTheCampusActiveNumber: (state, action) => {
      state.utilsTheCampusActiveNumber = action.payload;
    },
    setUtilsThecampusActiveSubmenuNumber: (state, action) => {
      state.utilsThecampusActiveSubmenuNumber = action.payload;
    },
  },
});

export default utilitiesSlice.reducer;
export const {
  setUtilsTheCampusActiveNumber,
  setUtilsThecampusActiveSubmenuNumber,
} = utilitiesSlice.actions;

import React, { Suspense, useState } from "react";
import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";

const Loading = React.lazy(() => import("./pages/Loading/Loading"));
const Verify = React.lazy(() => import("./pages/Verify/Verify"));
const Error = React.lazy(() => import("./pages/Error/Error"));
const SharedLayout = React.lazy(() =>
  import("./pages/SharedLayout/SharedLayout")
);
// TỔNG THỂ
const Policy = React.lazy(() => import("./pages/Policy/Policy"));

// THE CAMPUS
const TourTheCampus = React.lazy(() =>
  import("./pages/Tour/TourTheCampus")
);
const LocationTheCampus = React.lazy(() =>
  import("./pages/Location/LocationTheCampus")
);
const PlanTheCampus = React.lazy(() => import("./pages/Plan/PlanTheCampus"));
const ArchitectureTheCampus = React.lazy(() => import("./pages/Architecture/ArchitectureTheCampus"));
const UtilsTheCampus = React.lazy(() =>
  import("./pages/Utilities/UtilitiesTheCampus")
);
const EBrochureTheCampus = React.lazy(() =>
  import("./pages/EBrochure/EBrochureTheCampus")
);

function App() {
  const languageState = useSelector((state) => state.language);
  const { locale, messages } = languageState;
  const [checkVerify, setCheckVerify] = useState(true);

  return (
    <IntlProvider messages={messages} locale={locale}>
      <div className="App">
        <Suspense fallback={<div>Loading...</div>}>
          <Router>
            {checkVerify === true ? (
              <Routes>
                <Route path="/" index element={<Loading />} />
                <Route element={<SharedLayout />}>
                  <Route
                    index
                    path="/tour"
                    element={<TourTheCampus />}
                  />
                  <Route
                    index
                    path="/plan"
                    element={<PlanTheCampus />}
                  />
                   <Route
                    index
                    path="/architecture"
                    element={<ArchitectureTheCampus />}
                  />
                  <Route
                    index
                    path="/utils"
                    element={<UtilsTheCampus />}
                  />
                  <Route
                    index
                    path="/location"
                    element={<LocationTheCampus />}
                  />
                  <Route
                    index
                    path="/ebrochure"
                    element={<EBrochureTheCampus />}
                  />

                  <Route
                    index
                    path="/chinh-sach-bao-mat"
                    element={<Policy />}
                  />
                </Route>
                <Route path="*" element={<Error />} />
              </Routes>
            ) : (
              <Routes>
                <Route
                  path="*"
                  element={<Verify setCheckVerify={setCheckVerify} />}
                />
              </Routes>
            )}
          </Router>
        </Suspense>
      </div>
    </IntlProvider>
  );
}

export default App;
